import './standForm.scss'
import exhibit_img from '../../../assets/imgs/Ekaabo Website Layout exhibit.jpg'
import EkbHomeComponent from '../../../components/reusable/ekbHomeComponent/EkbHomeComponent'
import { useState } from 'react'

const RegisterToAttend = () => {
  const [clickedReadMore, setClickedReadMore] = useState(false)

  const hoc_content = 
  <div className='register-to-attend-form'>
    <p>Get to showcase your products and services to over 5,000 Tourism and Hospitality professionals
    </p>
    <div className='book-a-stand-stand-form-button-cont'>
      <button>REGISTER</button>
    </div>
    <form className='register-to-attend-form-form'>
      <input type='text' placeholder='Enter your first name' />
      <input type='text' placeholder='Enter your last name' />
      <input type='text' placeholder='Enter your job title'/>
      <input type='text' placeholder='Company name'/>
      <input type='tel' placeholder='Phone number'/>
      <input type='email' placeholder='Your email'/>

    </form>

  </div>



  return (
    <div>
      {
      !clickedReadMore &&
      <EkbHomeComponent 
      hoc_content={hoc_content} 
      heading="REGISTER FORM"
      img_src={exhibit_img}
      img_alt="exhibit"/>
      }
      {
      clickedReadMore &&
      <div>
      </div>
      }
    </div>

  )
}

export default RegisterToAttend